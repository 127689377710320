import React, { ReactNode, useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button, Typography } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import Container from "@mui/material/Container";
import { useNavigate, NavLink as RouterNavLink } from "react-router-dom";
import TestStepTwo, { TestStepTwoFormValues } from "../TestStepTwo/TestStepTwo";
import TestStepOne from "../TestStepOne/TestStepOne";
import TestStepThree, { PracticeData, TestStatus } from "../TestStepThree/TestStepThree";
import TestStepFour, { TestSessionData } from "../TestStepFour/TestStepFour";
import TestStepCompleted from "../TestStepCompleted/TestStepCompleted";
import { StepIconProps } from "@mui/material/StepIcon";
import DoneIcon from "@mui/icons-material/Done";
import clsx from "clsx";
import { arrayBufferToBase64, CandidateTestData, GetDataForTestResponse, useGetCandidateByEmail, useGetCandidateTestData, useGetDataForTest, usePatchCandidate, usePostCandidate, usePostTestResults } from "../../hooks/useApi";
import { getPreviewEmail, isPreviewInUse } from "../../hooks/usePreview";
import { useFeature } from "flagged";
import { getLogger } from "../../utils/logger";
import { useFlags } from "../../context/auth";
import useTestUrlParams from "../../hooks/useTestUrlParams";
import useSaveCandidate from "../../hooks/useSaveCandidate";
import SubSteps from "./SubStepsEnum";

const log = getLogger();

enum Steps {
    StepOne = 0,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
}

interface NavLinkType {
    key: string;
    text: string;
    href: string;
    id: string;
    color: "inherit" | "default" | "primary" | "secondary" | undefined;
    float: string;
}

export interface GetTestDataResponse {
    companyName: string;
    active: boolean;
    textId: string;
    testId: string;
    duration: number;
    minNetWpm: number;
    minAccuracy: number;
    useLimits: boolean;
    showLimits: boolean;
    maxAttempts: number;
    note: {
        enabled: boolean;
        text: string;
    };
    infoFieldOne: {
        enabled: boolean;
        title: string;
    };
    infoFieldTwo: {
        enabled: boolean;
        title: string;
    };
    name: string;
    useCustomText: boolean;
}

const useStyles = makeStyles()((theme) => ({
    back: {
        width: "100%",
        maxWidth: "100%"
    },
    root: {
        width: "100%",
        maxWidth: "90%",
        margin: "0 auto",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "45%",
        left: "50%",
        '@media (max-width: 1024px)': {
            position: "relative",
            transform: "translate(0,0)",
            top: "10px",
            left: "0px",
            minWidth: "1018px",
            minHeight: "712px",
        },
        '@media (max-height: 800px)': {
            position: "relative",
            transform: "translate(0,0)",
            top: "10px",
            left: "0px",
            minWidth: "1018px",
            minHeight: "712px",
        },
    },
    stepper: {
        width: "100%",
        paddingLeft: "0px",
        backgroundColor: theme.palette.primary.main,
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    cardRoot: {
        display: "block",
        width: "100%",
        height: "600px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: "1px solid #0000001F",
        borderRadius: "4px",
        borderLeft: "8px solid #7684D0",
    },
    logo: {
        textAlign: "left",
        fontStyle: "italic",
        fontWeight: 700,
        fontFamily: '"Roboto- BoldItalic", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: "24px",
        lineHeight: "36px",
        textIndent: "40px",
    },
    logo_img: {
        position: "absolute",
    },
    logo_span: {
        fontFamily: '"Roboto- Italic", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
    },
    navButton: {
        textTransform: "none",
    },

    textPrimary: {
        textTransform: "none",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.main,
    },

    textSecondary: {
        textTransform: "none",
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.primary.main,
    },

    stepCompleted: {
        color: theme.palette.primary.dark + " !important",
    },
}));

const useStepIconStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: "#999999",
        zIndex: 1,
        color: theme.palette.primary.light,
        width: 32,
        height: 32,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
    },
    active: {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.light,
    },
    completed: {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.light,
    },
}));

const year = new Date().getFullYear();

const navLinks: NavLinkType[] = [
    { key: "1", text: "Contact test administrator", href: "", id: "btn_center_contact", color: "primary", float: "left" },
    { key: "2", text: "Support", href: "https://typingtestpro.zendesk.com/hc/en-us/categories/4545188731666", id: "btn_center_support", color: "primary", float: "left" },
    { key: "3", text: `© Typing Master Inc. ${year}`, href: "/heartbeat", id: "btn_center_version", color: "secondary", float: "right" },
    { key: "4", text: "Admin login", href: "/login", id: "btn_center_admin", color: "secondary", float: "right" },

];

const getSteps = () => {
    return ["Test Info", "Personal Info", "Practice", "Typing Test", "Test Summary"];
};

export interface PersonalData {
    email: string;
    firstName: string;
    lastName: string;
    consentAccepted: boolean;
    candidateId: string;
    infoFieldOneValue: string;
    infoFieldTwoValue: string;
    emailResults: boolean;
}

const initialPersonalData: PersonalData = {
    email: "",
    firstName: "",
    lastName: "",
    consentAccepted: false,
    candidateId: "",
    infoFieldOneValue: "",
    infoFieldTwoValue: "",
    emailResults: true
};

const initialTestInfo: GetDataForTestResponse = {
    companyName: "",
    active: false,
    textId: "",
    testId: "",
    duration: 0,
    minNetWpm: 0,
    minAccuracy: 0,
    useLimits: true,
    showLimits: true,
    maxAttempts: 0,
    note: {
        enabled: false,
        text: ""
    },
    infoFieldOne: {
        enabled: false,
        title: ""
    },
    infoFieldTwo: {
        enabled: false,
        title: ""
    },
    name: "",
    useCustomText: false,
    logo: {

        data: {
            "data": [],
        },
        type: ""

    },
    exitPassUrl: {
        enabled: false,
        title: ""
    },
    exitFailUrl: {
        enabled: false,
        title: ""
    },
    newAttemptsAllowed: -1,
    accessCode: {
        enabled: false,
        title: ""
    },
    flags: '',
    disableExitParameters: false,
};

const initialCandidateTestData: CandidateTestData = {
    attemptsLeft: -1,
    bestResult: {
        netWpm: 0,
        accuracy: 0,
        status: "FAIL",
        createdAt: new Date(Date.now()),
    },
};

const TestWizard = () => {
    const { classes } = useStyles();
    const [activeStep, setActiveStep] = useState(Steps.StepOne);
    const [errorMessage, setErrorMessage] = useState<JSX.Element>();
    const showElements = useFeature("d_ce_s");

    const {
        testId,
        email,
        firstName,
        lastName,
        customField1,
        customField2,
    } = useTestUrlParams();

    const candidate = useSaveCandidate({
        testId,
        email,
        firstName,
        lastName,
        customField1,
        customField2,
    });

    const [testInfo, setTestInfo] = useState<GetDataForTestResponse>(initialTestInfo);
    const [testResponseData, setTestResponseData] = useState<{ received: boolean; errorStatus?: string }>({ received: false, errorStatus: '' });
    const [imageData, setImageData] = useState("");
    const { setFeatures } = useFlags();

    const navigate = useNavigate();

    useEffect(() => {
        if (isPreviewInUse()) {
            const email = getPreviewEmail();
            setPersonalData({
                email: email,
                firstName: "--",
                lastName: "--",
                consentAccepted: true,
                candidateId: "-",
                infoFieldOneValue: "--",
                infoFieldTwoValue: "--",
                emailResults: false
            });
        }
        useGetDataForTest(testId, (data) => {
            log.debug(`onData() - ${JSON.stringify(data)}`);

            if (data.logo) {
                const imageString = arrayBufferToBase64(data.logo.data.data);
                setImageData(`data:${data.logo.type};base64,${imageString}`);
            }
            setTestInfo({
                companyName: data.companyName,
                active: data.active,
                textId: data.textId,
                testId: data.testId,
                duration: data.duration,
                minNetWpm: data.minNetWpm,
                minAccuracy: data.minAccuracy,
                useLimits: data.useLimits,
                showLimits: data.showLimits,
                maxAttempts: data.maxAttempts,
                note: data.note,
                infoFieldOne: data.infoFieldOne,
                infoFieldTwo: data.infoFieldTwo,
                name: data.name,
                useCustomText: data.useCustomText,
                logo: data.logo,
                exitPassUrl: data.exitPassUrl,
                exitFailUrl: data.exitFailUrl,
                newAttemptsAllowed: data.newAttemptsAllowed,
                accessCode: data.accessCode,
                flags: data.flags,
                disableExitParameters: data.disableExitParameters,
            });
            if (data.flags) {
                setFeatures(data.flags.split(','));
            }
            setTestResponseData({ received: true });
        },
            (errorStatus) => {
                log.error(`onError() - ${errorStatus}`);
                setTestResponseData({ received: true, errorStatus });
            })
    }, []);

    useEffect(() => {
        if (candidate && typeof candidate.attemptsLeft === 'number') {
            setCandidateTestData((prev) => {
                return {
                    ...prev,
                    attemptsLeft: candidate.attemptsLeft
                }
            });
        }
    }, [candidate]);

    if (!testId) {
        log.debug(`testId is not defined`);
        navigate("/");
    }

    const getTestStatus = (netWpm: string, accuracy: string): TestStatus => {
        if (parseFloat(netWpm) >= testInfo.minNetWpm && parseFloat(accuracy) >= testInfo.minAccuracy) {
            return "PASS";
        }
        return "FAIL";
    }

    const [disableOtherThanEmail, setDisableOtherThanEmail] = useState(true);
    const [emailChecked, setEmailChecked] = useState(false);
    const [emailFound, setEmailFound] = useState(false);

    const checkEmail = (email: string): Promise<{ found: boolean, isNetworkError?: boolean }> => {
        log.debug(`checkEmail() - checking email...`);
        if (isPreviewInUse()) {
            setDisableOtherThanEmail(false);
            setCandidateTestData((prev) => {
                return {
                    ...prev,
                    attemptsLeft: testInfo.maxAttempts
                }
            });
            return Promise.resolve({ found: false });
        }
        return new Promise((resolve) => {
            const emailLowerCase = email.toLowerCase();
            useGetCandidateByEmail({ testId: testInfo.testId, email: emailLowerCase }, (data) => {
                log.debug(`checkEmail() - response: ${JSON.stringify(data)}`);
                setPersonalData({
                    email: emailLowerCase,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    consentAccepted: false,
                    candidateId: data.candidateId,
                    infoFieldOneValue: data.infoFieldOneValue,
                    infoFieldTwoValue: data.infoFieldTwoValue,
                    emailResults: data.emailResults
                });
                setCandidateTestData((prev) => {
                    return {
                        ...prev,
                        attemptsLeft: data.attemptsLeft
                    }
                });
                setEmailFound(true);
                setDisableOtherThanEmail(true);
                resolve({ found: true });
            }, (isNetworkError) => {
                if (isNetworkError) {
                    log.debug(`checkEmail() - network error`);
                    resolve({ found: false, isNetworkError: true });
                } else {
                    log.debug(`checkEmail() - email not found`);
                    setDisableOtherThanEmail(false);
                    resolve({ found: false });
                }
                setDisableOtherThanEmail(false);
            });
        });
    }

    const [subStep, setSubStep] = useState<SubSteps>(SubSteps.SubStepOne);

    const initialPracticeData: PracticeData = {
        acc: "",
        nwpm: "",
        gwpm: "",
        ncpm: "",
        gcpm: "",
        dur: "",
        time: "",
        err: "",
        hits: "",
        status: "NOT_STARTED"
    };

    const [practiceData, setPracticeData] = useState(initialPracticeData);

    const [personalData, setPersonalData] = useState(initialPersonalData);

    const postOrUpdatePersonalData = async (values: TestStepTwoFormValues, onError?: (reason: "network" | "unknown") => void): Promise<void> => {
        if (isPreviewInUse()) {
            return handleNextStep();
        }
        if (candidate) {
            updatePersonalData(personalData, candidate.candidateId);
            return handleNextStep();
        }
        const valuesToSave = { ...values, email: values.email.toLowerCase() };
        if (personalData.candidateId === "") {
            log.debug(`postOrUpdatePersonalData() - creating candidate: ${JSON.stringify(valuesToSave, null, 2)}`);
            usePostCandidate({ testId: testInfo.testId, ...valuesToSave, emailResults: personalData.emailResults },
                (candidateId: string) => {
                    log.debug(`postOrUpdatePersonalData() - response: ${candidateId}`);
                    updatePersonalData(valuesToSave, candidateId);
                    setEmailChecked(true);
                    handleNextStep();
                }, () => {
                    log.error(`postOrUpdatePersonalData() - error`);
                    handleError();
                });
        } else {
            if (!emailFound) {
                log.debug(`postOrUpdatePersonalData() - updating existing candidate: ${JSON.stringify(valuesToSave, null, 2)}`);
                usePatchCandidate({ testId: testInfo.testId, candidateId: personalData.candidateId, emailResults: personalData.emailResults, ...valuesToSave },
                    (candidateId: string) => {
                        log.debug(`postOrUpdatePersonalData() - response: ${candidateId}`);
                        updatePersonalData(valuesToSave, candidateId);
                        handleNextStep();
                    }, ({ reason }) => {
                        log.error(`postOrUpdatePersonalData() - error`);
                        if (reason === "network" && onError) {
                            onError(reason);
                        } else {
                            handleError();
                        }
                    });
            } else {
                log.debug(`postOrUpdatePersonalData() - returning candidate, not updating`);
                handleNextStep();
            }

        }
    }

    const initialTestData: TestSessionData = {
        attemptsUsed: 0,
    };

    const [testData, setTestData] = useState(initialTestData);

    const [candidateTestData, setCandidateTestData] = useState<CandidateTestData>(initialCandidateTestData);

    const updateCandidateTestData = (newCandidateTestData: CandidateTestData) => {
        setTestData((prev) => {
            const newState = {
                attemptsUsed: testInfo.maxAttempts - newCandidateTestData.attemptsLeft,
            };
            return newState;
        });
        setCandidateTestData(newCandidateTestData);
    }

    const updatePersonalData = (values: TestStepTwoFormValues, candidateId: string): void => {
        setPersonalData((previous) => {
            return {
                ...previous,
                ...values,
                candidateId: candidateId,
            };
        });
    };

    const initialTypingTestData: PracticeData = {
        acc: "",
        nwpm: "",
        gwpm: "",
        ncpm: "",
        gcpm: "",
        dur: "",
        time: "",
        err: "",
        hits: "",
        status: "NOT_STARTED"
    };

    const [typingTestData, setTypingTestData] = useState<PracticeData>(initialTypingTestData);

    const handleNewAttempt = () => {
        if (isPreviewInUse()) {
            updateCandidateTestData(
                {
                    ...candidateTestData,
                    attemptsLeft: testInfo.maxAttempts - testData.attemptsUsed,
                });
        } else {
            useGetCandidateTestData(testInfo.testId, personalData.candidateId, updateCandidateTestData);
        }
        setSubStep(SubSteps.SubStepOne);
        handleReset();
    }

    const initTestResult = async (onInitReady: () => void) => {
        const newAttemptsUsed = testData.attemptsUsed + 1;
        setTestData(() => {
            return { attemptsUsed: newAttemptsUsed };
        });
        setCandidateTestData((prev) => { return { ...prev, attemptsLeft: testInfo.maxAttempts - newAttemptsUsed }; });
        if (isPreviewInUse()) {
            return onInitReady();
        } else {
            usePostTestResults(
                {
                    testId: testInfo.testId,
                    candidateId: personalData.candidateId
                },
                (response) => {
                    sessionStorage.setItem("resultId", response.resultId);
                    return onInitReady();
                });
        }
    };

    const isNewRecord = (netWpm: number, accuracy: number): boolean => {
        const currentBestString = sessionStorage.getItem("bestResult");
        if (!currentBestString) {
            return true;
        }
        const best = JSON.parse(currentBestString);

        log.debug(`isNewRecord() - netWpm: ${netWpm}, accuracy: ${accuracy}}, best: ${JSON.stringify(best, null, 2)}`);
        if (netWpm > best.netWpm) {
            log.debug(`isNewRecord() - netWpm better, returning true`);
            return true;
        }
        if (netWpm === best.netWpm && accuracy > best.accuracy
        ) {
            log.debug(`isNewRecord() - netWpm same, accuracy better, returning true`);
            return true;
        }
        log.debug(`isNewRecord() - returning false`);
        return false;
    };

    const updateBestResult = (netWpm: number, accuracy: number): void => {
        if (isPreviewInUse()) {
            log.debug(`updateBestResult() - in preview mode`);
            if (isNewRecord(netWpm, accuracy)) {
                const newBestResult = { netWpm: 0, accuracy: 0, status: "PASS", createdAt: new Date(Date.now()) };
                newBestResult.netWpm = netWpm;
                newBestResult.accuracy = accuracy;
                newBestResult.status = getTestStatus("" + netWpm, "" + accuracy);
                setCandidateTestData((prev) => {
                    return { ...prev, bestResult: newBestResult };
                });
                sessionStorage.setItem("bestResult", JSON.stringify(newBestResult));
            }
        }
    }

    const setupSessionStorageForPractice = () => {
        sessionStorage.setItem("showClose", "false");
    }

    const setupSessionStorage = () => {
        setupSessionStorageForPractice();
        if (testInfo.useCustomText) {
            sessionStorage.setItem("textId", "custom_text");
        } else {
            sessionStorage.setItem("textId", testInfo.textId);
        }
        sessionStorage.setItem("duration", "" + testInfo.duration);
    }

    const getStepContent = (step: number) => {
        switch (step) {
            case Steps.StepOne:
                return (
                    <TestStepOne
                        handleNext={handleNextStep}
                        companyName={testInfo.companyName}
                        duration={testInfo.duration}
                        maxAttempts={testInfo.maxAttempts}
                        active={testInfo.active}
                        minNetWpm={testInfo.minNetWpm}
                        minAccuracy={testInfo.minAccuracy}
                        useLimits={testInfo.useLimits}
                        showLimits={testInfo.showLimits}
                        note={testInfo.note}
                        dataReceived={testResponseData.received}
                        name={testInfo.name}
                        accessCode={testInfo.accessCode}
                        imageData={imageData}
                        testId={testInfo.testId}
                        testResponseErrorStatus={testResponseData.errorStatus}
                    />

                );
            case Steps.StepTwo:
                return (
                    <TestStepTwo
                        testId={testInfo.testId}
                        newAttemptsAllowed={testInfo.newAttemptsAllowed}
                        handleNext={postOrUpdatePersonalData}
                        handleBack={handleBack}
                        initialEmail={personalData.email}
                        initialFirstName={personalData.firstName}
                        initialLastName={personalData.lastName}
                        infoFieldOneEnabled={testInfo.infoFieldOne.enabled}
                        infoFieldOneTitle={testInfo.infoFieldOne.title}
                        initialInfoFieldOneValue={personalData.infoFieldOneValue}
                        infoFieldTwoEnabled={testInfo.infoFieldTwo.enabled}
                        infoFieldTwoTitle={testInfo.infoFieldTwo.title}
                        initialInfoFieldTwoValue={personalData.infoFieldTwoValue}
                        initialConsentAccepted={personalData.consentAccepted}
                        personalData={personalData}
                        checkEmail={checkEmail}
                        disableOtherThanEmail={disableOtherThanEmail}
                        emailChecked={emailChecked}
                        attemptsLeft={candidateTestData.attemptsLeft}
                        emailFound={emailFound}
                        candidate={candidate}
                    />
                );
            case Steps.StepThree:
                return (
                    <TestStepThree
                        handleNext={handleNextStep}
                        handleBack={handleBack}
                        practiceData={practiceData}
                        resetPracticeData={() => {
                            setPracticeData(initialPracticeData);
                        }}
                        setPracticeData={setPracticeData}
                        getTestStatus={getTestStatus}
                        setSubStep={setSubStep}
                        subStep={subStep}
                        setupSessionStorage={setupSessionStorageForPractice}
                    />
                );
            case Steps.StepFour:
                return (
                    <TestStepFour
                        handleNext={handleNextStep}
                        handleBack={handleBack}
                        handleNextAttempt={handleReset}
                        testData={testData}
                        testId={testInfo.testId}
                        textId={testInfo.textId}
                        candidateId={personalData.candidateId}
                        updateCandidateTestData={updateCandidateTestData}
                        setTypingTestData={setTypingTestData}
                        typingTestData={typingTestData}
                        getTestStatus={getTestStatus}
                        onEmailResultsChange={(checked) => {
                            log.debug(`onEmailResultsChange() - checked: ${checked}`);
                            setPersonalData((prev) => {
                                return {
                                    ...prev,
                                    emailResults: checked
                                }
                            });
                            usePatchCandidate({ testId: testInfo.testId, candidateId: personalData.candidateId, emailResults: checked },
                                (candidateId: string) => {
                                    log.debug(`onEmailResultsChange() - response: ${candidateId}`);
                                }, () => {
                                    log.error(`onEmailResultsChange() - error`);
                                    handleError('Result emailing is not available during the preview');
                                });
                        }}
                        emailResults={personalData.emailResults}
                        candidateTestData={candidateTestData}
                        setSubStep={setSubStep}
                        subStep={subStep}
                        useCustomText={testInfo.useCustomText}
                        handleNewAttempt={handleNewAttempt}
                        initTestResult={initTestResult}
                        testInfo={testInfo}
                        updateBestResult={updateBestResult}
                        showLimits={testInfo.showLimits}
                        useLimits={testInfo.useLimits}
                        setupSessionStorage={setupSessionStorage}
                    />
                );
            case Steps.StepFive:
                return (
                    <TestStepCompleted
                        handleBack={handleBack}
                        handleNextAttempt={handleReset}
                        testInfo={testInfo}
                        personalData={personalData}
                        typingTestData={typingTestData}
                        updateCandidateTestData={updateCandidateTestData}
                        candidateTestData={candidateTestData}
                        showLimits={testInfo.showLimits}
                        useLimits={testInfo.useLimits}
                        imageData={imageData}
                        candidate={candidate}
                    />
                );
            default:
                return "Unknown step";
        }
    };

    const steps = getSteps();

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSubStep(SubSteps.SubStepOne);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSubStep(SubSteps.SubStepOne);
    };
    const handleReset = () => {
        setActiveStep(Steps.StepFour);
    };
    const handleError = (message?: string) => {
        setErrorMessage(<Typography color="error">{message || 'Error, try again later'}</Typography>);
    };

    const appLogo = () => {
        return (
            <div>
                <img alt="TypingMater logo" src="/assets/img/logo.svg" className={classes.logo_img} />

                <Typography variant="h6" component="h1" className={classes.logo}>
                    TypingTest<span className={classes.logo_span}> Pro </span>
                </Typography>
            </div>
        );
    };

    const navButton = (props: NavLinkType) => {
        if (props.href.indexOf("https://") >= 0 || props.href.indexOf("http://") >= 0) {

            return (
                <a key={props.key} href={props.href} target="_blank" className={classes.navButton} style={{ float: props.float === "left" ? "left" : "right" }}>
                    <Button variant="text" sx={{ bgcolor: props.color }} classes={{ textPrimary: classes.textPrimary, textSecondary: classes.textSecondary }} disableElevation>
                        {props.text}
                    </Button>
                </a>
            );
        } else {

            return (
                <RouterNavLink
                    key={props.key}
                    to={props.href}
                    className={({ isActive }) => isActive ? classes.navButton : classes.navButton}
                    style={{ float: props.float === "left" ? "left" : "right" }}
                >
                    <Button variant="text" sx={{ bgcolor: props.color }} classes={{ textPrimary: classes.textPrimary, textSecondary: classes.textSecondary }} disableElevation>
                        {props.text}
                    </Button>
                </RouterNavLink>
            );
        };
    };

    const getNavButtons = () => {
        if (showElements) {
            return navLinks.map(({ key, text, href, id, color, float }) => {
                return (
                    <React.Fragment key={key}>
                        {navButton({ key, text, href, id, color, float })}
                    </React.Fragment>
                );
            });
        }
        return navLinks.slice(1, 4).map(({ key, text, href, id, color, float }) => {
            return (
                <React.Fragment key={key}>
                    {navButton({ key, text, href, id, color, float })}
                </React.Fragment>
            );
        });
    };

    function StepIcon(props: StepIconProps) {
        const { classes } = useStepIconStyles();
        const { active, completed } = props;

        let elem: ReactNode = <DoneIcon />;
        if (!props.completed) {
            elem = String(props.icon);
        }

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {elem}
            </div>
        );
    }

    return (
        <Container className={classes.back}>
            <div className={classes.root}>
                {appLogo()}
                <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((label) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}
                            >
                                <StepLabel {...labelProps} StepIconComponent={StepIcon}
                                    classes={{
                                        completed: classes.stepCompleted,
                                    }}>
                                    {label}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    <Typography component={"span"} className={classes.cardRoot}>
                        {errorMessage}
                        {getStepContent(activeStep)}
                    </Typography>
                </div>
                {getNavButtons()}
            </div>
        </Container>
    );
};

export default TestWizard;
